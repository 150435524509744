.bd-navbar {
  padding: .625rem 0;
  background-color: $blue;

  @include media-breakpoint-down(md) {
    .navbar-nav-scroll {
      width: 100%;
      margin-top: .25rem;

      .navbar-nav {
        padding: .5rem 0;
        margin: -.5rem 0;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  .navbar-nav {
    list-style: none;

    .nav-link {
      color: rgba($white, .85);
      padding: 0.5rem 1rem;

      &:hover,
      &:focus {
        color: $white;
      }

      &.active {
        font-weight: 600;
        color: $white;
      }
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }
}
