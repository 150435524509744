@import "../functions";
@import "../variables";
@import "../mixins";

// Load docs components
@import "variables";
@import "navbar";
// @import "subnav";
// @import "masthead";
// @import "ads";
@import "content";
// @import "skippy";
@import "sidebar";
@import "toc";
// @import "footer";
@import "component-examples";
@import "buttons";
@import "callouts";
// @import "brand";
// @import "colors";
@import "clipboard-js";
// @import "placeholder-img";

// Load docs dependencies
@import "syntax";
// @import "anchor";
// @import "algolia";

@import "tooltip";
@import "tables";
