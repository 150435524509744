//
// Callouts
//

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid $gray-200;
  border-left-width: .25rem;
  @include border-radius();

  h4 {
    margin-bottom: .25rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  code {
    @include border-radius();
  }

  + .bd-callout {
    margin-top: -.25rem;
  }

  > p {
    margin: 1rem 0;
  }

  > ul, ol {
    list-style: initial;
  }

  > ul li,
  > ol li {
    margin-left: 1.25rem;
    margin-bottom: .25rem;
  }
}

// Variations
@mixin bs-callout-variant($color) {
  border-left-color: $color;

  h4 { color: $color; }
}

.bd-callout-info { @include bs-callout-variant($bd-info); }
.bd-callout-warning { @include bs-callout-variant($bd-warning); }
.bd-callout-danger { @include bs-callout-variant($bd-danger); }
