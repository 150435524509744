
// Buttons
//
// Custom buttons for the docs.
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover {
  color: #fff;
  background-color: #025ce2;
  border-color: #0257d5;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #025ce2;
  border-color: #0257d5;
  box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}

.btn-bd-primary {
  font-weight: 600;
  color: $bd-purple-bright;
  border-color: $bd-purple-bright;

  &:hover,
  &:active {
    color: $white;
    background-color: $bd-purple-bright;
    border-color: $bd-purple-bright;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($bd-purple-bright, .25);
  }
}

.btn-bd-download {
  font-weight: 600;
  color: $bd-download;
  border-color: $bd-download;

  &:hover,
  &:active {
    color: $bd-dark;
    background-color: $bd-download;
    border-color: $bd-download;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($bd-download, .25);
  }
}

.btn-bd-light {
  color: $gray-600;
  border-color: $gray-300;

  .show > &,
  &:hover,
  &:active {
    color: $bd-purple-bright;
    background-color: $white;
    border-color: $bd-purple-bright;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($bd-purple-bright, .25);
  }
}
