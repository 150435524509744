//
// Bootstrap docs content theming
//

.bd-content {
  order: 1;
  line-height: 1.5;

  > a {
    color: $link-color;
    text-decoration: $link-decoration;

    &:hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }

  > p {
    margin-bottom: 1rem;
  }

  li > p {
    margin-bottom: 0;
  }

  // Hack the sticky header
  // stylelint-disable selector-no-qualifying-type
  > h2[id],
  > h3[id],
  > h4[id] {
    pointer-events: none;
    margin: 1rem 0;
    // &::before {
    //   display: block;
    //   height: 6rem;
    //   margin-top: -6rem;
    //   content: "";
    // }
    // stylelint-enable selector-no-qualifying-type
  }

  > h2[id] {
    font-size: 2rem;
  }

  > h3[id] {
    font-size: 1.75rem;
  }

  > h4[id] {
    font-size: 1.5rem;
  }

  > ul, ol {
    list-style: initial;
  }

  > ul li,
  > ol li {
    margin-left: 1.25rem;
    margin-bottom: .25rem;
  }

  // Override Bootstrap defaults
  > .table {
    max-width: 100%;
    margin-bottom: 1.5rem;

    @include media-breakpoint-down(md) {
      display: block;
      overflow-x: auto;

      &.table-bordered {
        border: 0;
      }
    }

    th,
    td {
      &:first-child {
        padding-left: 0;
      }

      &:not(:last-child) {
        padding-right: 1.5rem;
      }
    }

    // Prevent breaking of code
    td:first-child > code {
      white-space: nowrap;
    }
  }
}

.bd-content-title {
  display: block;
  pointer-events: auto;
}

.bd-title {
  @include font-size(3rem);
}

.bd-lead {
  @include font-size(1.5rem);
  font-weight: 300;
}

.bd-text-purple-bright {
  color: $bd-purple-bright;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

// text guide
.text-guide {
  position: relative;
  background-color: $gray-400;

  // span:first-child {
  //   overflow: hidden;
  //   display: inline-block;
  //   line-height: 1;
  //   background-color: rgba(red, .5);
  //   vertical-align: top;
  // }
}
