// stylelint-disable selector-max-type, selector-max-combinators, selector-max-compound-selectors

.bd-toc nav {
  padding-top: .125em;
  padding-bottom: .125em;
  border-left: .25em solid $gray-200;

  ul {
    padding-left: 1rem;
    margin-bottom: 0;
  }

  li ul li {
    margin-bottom: .25rem;
    list-style-type: disc;
  }

  a code {
    font: inherit;
  }
}
